import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { NavigatorService } from "../services/engine/navigator.service";

@Component({
  selector: "app-operating-mode-menu",
  templateUrl: "./operating-mode-menu.component.html",
  styleUrls: ["./operating-mode-menu.component.scss"]
})
export class OperatingModeMenuComponent implements OnDestroy {
  public readonly pathIconeGreen: string = "assets/captor_state_green.svg";
  public readonly pathIconeRed: string = "assets/captor_state_assemble.svg";
  public readonly pathIconeUndefined: string =
    "assets/captor_state_undefined.svg";
  public readonly pathcheckUndefined: string = "assets/validate_white.png";
  public readonly pathcheckRed: string = "assets/validate_red.png";
  public readonly pathcheckGreen: string = "assets/validate_green.png";

  private subscription: Subscription = new Subscription();

  public iconePath: string = this.pathIconeUndefined;
  public selection: string | undefined;

  public navigators = [
    {
      name: "Assembly",
      icon: this.pathIconeGreen,
      checked: this.pathcheckGreen,
      isSelected: false
    },
    {
      name: "Disassembly",
      icon: this.pathIconeRed,
      checked: this.pathcheckRed,
      isSelected: false
    },
    {
      name: "Inventory",
      icon: this.pathIconeGreen,
      checked: this.pathcheckGreen,
      isSelected: false
    },
    {
      name: "Adelie project - X8724",
      icon: this.pathIconeGreen,
      checked: this.pathcheckGreen,
      isSelected: false
    }
  ];

  constructor(private navigatorService: NavigatorService) {
    this.subscription.add(
      this.navigatorService.getNav().subscribe((navigations: any) => {
        const newNav: any[] = [];
        for (let i = 0; i < this.navigators.length; i++) {
          if (navigations.navModes[i].hasRight) {
            newNav.push(this.navigators[i]);
          }
        }
        this.navigators = newNav;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public choiceUpdated(selectedNav: any) {
    for (const nav of this.navigators) {
      nav.isSelected = false;
    }
    selectedNav.isSelected = true;
    this.iconePath = selectedNav.icon;
    this.selection = selectedNav.name;
  }

  public choiceMade() {
    this.navigatorService.setMode(this.selection);
    this.navigatorService.goNext();
  }
}
