import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ContainerType } from "../models";
import { ProvisionerService } from "../services/engine/provisioner.service";

@Component({
  selector: "app-container-flash",
  templateUrl: "./container-flash.component.html",
  styleUrls: ["./container-flash.component.scss"]
})
export class ContainerFlashComponent implements OnInit, OnDestroy {
  ctControl = new UntypedFormControl();
  cts: ContainerType[] = [];
  filteredContainerTypes: Observable<ContainerType[]>;

  selectedCt: ContainerType;
  selectedDevice: MediaDeviceInfo;

  backSuscriber;

  cameraError = "";
  cameraStyle = { height: "0" };
  qrCodeWidth = 0;

  cameraEnabled = false;
  permissionAsked = false;

  callingForContainer = true;
  connectionError = false;

  constructor(
    private provisioning: ProvisionerService,
    public dialog: MatDialog
  ) {
    this.provisioning.enableActions.emit(false);
    this.provisioning.getContainerTypes();
    this.provisioning.containerTypeEvent.subscribe((containerTypes) => {
      this.provisioning.enableActions.emit(true);
      if (containerTypes !== undefined && containerTypes.length !== 0) {
        this.connectionError = false;
        this.callingForContainer = false;
        this.cts = [];
        for (const ct of containerTypes) {
          if (ct.code.toUpperCase() !== "TORN") {
            this.cts.push(ct);
          }
        }
        this.filteredContainerTypes = this.ctControl.valueChanges.pipe(
          startWith(""),
          map((value) => (value ? this.filterCT(value) : this.cts.slice()))
        );
      } else {
        if (this.callingForContainer) {
          this.connectionError = true;
          setTimeout(() => {
            this.provisioning.getContainerTypes();
          }, 5000);
        }
      }
    });
  }

  async ngOnInit() {
    this.backSuscriber = this.provisioning.backButtonPressed.subscribe(() => {
      this.provisioning.confirmBack(true);
    });

    this.selectedCt = null;
  }

  async ngOnDestroy() {
    this.callingForContainer = false;
    this.backSuscriber.unsubscribe();
  }

  private filterCT(value: string): ContainerType[] {
    const filterValue = value.toLowerCase();
    if (filterValue === "") {
      return this.cts;
    }

    return this.cts.filter((ct) => ct.code.toLowerCase().includes(filterValue));
  }

  public selectCt(ct: ContainerType): void {
    this.selectedCt = ct;
    this.displayConfirmCTDialog();
  }

  public validateContainerType(containerType: string): ContainerType | null {
    for (const ct of this.cts) {
      if (ct.code.toLowerCase() === containerType.toLowerCase()) {
        return ct;
      }
    }

    return null;
  }

  public handleResult(resultString: string): void {
    if (
      this.cameraEnabled &&
      (this.selectedCt === undefined ||
        this.selectedCt === null ||
        (this.selectedCt.code !== resultString &&
          this.validateContainerType(resultString) !== null))
    ) {
      this.selectedCt = this.validateContainerType(resultString);
      this.displayConfirmCTDialog();
    }
  }

  private displayConfirmCTDialog(): void {
    if (this.selectedCt !== undefined && this.selectedCt !== null) {
      this.provisioning.chooseContainerType(this.selectedCt);
      this.provisioning.finishFlashCT.emit(true);
    }
  }

  close() {
    this.cameraEnabled = false;
    this.cameraStyle = { height: "0" };
    this.qrCodeWidth = 0;
  }

  showCamera() {
    this.cameraEnabled = true;
    this.cameraStyle = { height: "auto" };
    this.qrCodeWidth = 50;
  }

  public async reloadPermissions() {
    this.provisioning.reloadCTFlash.emit();
  }
}
