import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  NgZone
} from "@angular/core";
import QrScanner from "qr-scanner";

interface QrScannerModes {
  original: QrScanner.InversionMode;
  invert: QrScanner.InversionMode;
  both: QrScanner.InversionMode;
}

@Component({
  selector: "app-qr-scanner",
  templateUrl: "./qr-scanner.component.html",
  styleUrls: ["./qr-scanner.component.scss"]
})
export class QrScannerComponent implements OnDestroy, OnChanges {
  @ViewChild("videoElement") videoElement!: ElementRef<HTMLVideoElement>;

  @Input() cameraEnabled: boolean = false;
  @Input() isFlashOn: boolean = false;
  @Input() cameraActive: string; // camera selected id

  @Output() camerasList: EventEmitter<any> = new EventEmitter();
  @Output() scanSuccess: EventEmitter<String> = new EventEmitter();
  @Output() flashCompatible: EventEmitter<boolean> = new EventEmitter();

  public qrScanner!: QrScanner;
  public qrScannerModes: QrScannerModes = {
    original: "original",
    invert: "invert",
    both: "both"
  };

  private qrCodeDebounce: NodeJS.Timeout;

  constructor(private ngZone: NgZone) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cameraEnabled) {
      this.toggleCamera();
    }
    if (changes.isFlashOn) {
      this.toggleQrScannerFlash();
    }
    if (changes.cameraActive) {
      this.toggleQrScannerCamera();
    }
  }

  private toggleCamera(): void {
    if (this.cameraEnabled) {
      this.initializeQrScanner();
      this.startQrScanner();
    } else {
      this.stopQrScanner();
    }
  }

  initializeQrScanner(): void {
    const preferredCameraId: string = localStorage.getItem("preferredCameraId");
    console.log("Preferred camera found: ", preferredCameraId);

    this.qrScanner = new QrScanner(
      this.videoElement.nativeElement,
      ({ data }) => {
        this.ngZone.run(() => {
          this.debounceQrCodeScanned(data);
        });
      },
      {
        onDecodeError: () => {},
        preferredCamera: preferredCameraId ? preferredCameraId : "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true
      }
    );
  }

  toggleQrScannerMode(value: QrScanner.InversionMode) {
    this.qrScanner.setInversionMode(value);
  }

  toggleQrScannerFlash(): void {
    if (this.qrScanner) {
      this.qrScanner
        .toggleFlash()
        .then(() =>
          console.log("flash: ", this.qrScanner.isFlashOn() ? "on" : "off")
        );
    }
  }

  toggleQrScannerCamera(): void {
    if (this.qrScanner && this.cameraActive) {
      this.qrScanner.setCamera(this.cameraActive).then(() => {
        this.qrScannerFlashCompatible();
      });
    }
  }

  startQrScanner(): void {
    this.qrScanner.start().then(() => {
      this.qrScannerFlashCompatible();

      QrScanner.listCameras(true).then((cameras) => {
        this.camerasList.emit(cameras);
      });
    });
    this.qrScanner.setInversionMode(this.qrScannerModes.both);
  }

  stopQrScanner(): void {
    if (this.qrScanner) {
      this.qrScanner.stop();
      this.qrScanner.destroy();
    }
  }

  qrScannerFlashCompatible(): void {
    this.qrScanner.hasFlash().then((hasFlash) => {
      this.flashCompatible.emit(hasFlash);
    });
  }

  debounceQrCodeScanned(QrScanData: string): void {
    if (this.qrCodeDebounce) {
      return;
    }

    this.scanSuccess.emit(QrScanData);

    this.qrCodeDebounce = setTimeout(() => {
      this.qrCodeDebounce = null;
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.qrScanner) {
      this.qrScanner.destroy();
    }
    if (this.qrCodeDebounce) {
      clearTimeout(this.qrCodeDebounce);
    }
  }
}
